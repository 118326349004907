import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"

import "../css/@index/style.scss"

interface ListPostByTagPageProps {
  data: {
    allWpTag: {
      nodes: {
        name?: string;
        seo?: {
          breadcrumbs?: {
            text?: string;
            url?: string;
          }[];
          canonical?: string;
          metaDesc?: string;
          opengraphImage?: string;
          title?: string;
        }
      }[];
    };
    allWpPost: {
      totalCount?: number;
      edges?: {
        node: {
          categories: {
            nodes: {
              name: string;
              slug: string;
            }[];
          };
          date?: Date;
          excerpt?: string;
          seo?: {
            opengraphImage?: {
              mediaItemUrl?: string;
            }
          };
          title?: string;
          uri: string;
        }
      }[];
    };
  };
  pageContext: {
    tagName?: string;
    humanPageNumber: number;
    limit: number;
    nextPagePath?: string;
    numberOfPages?: number;
    pageNumber?: number;
    previousPagePath?: string;
    skip: number;
    slug?: string;
  }
}

const ListPostByTag = ({
  data,
  pageContext
}: ListPostByTagPageProps) => {

  const tagName = data.allWpTag?.nodes[0]?.name || ''

  const totalPosts = data.allWpPost?.totalCount || 0
  const posts = data.allWpPost?.edges || []

  let postsList = [...posts]
  postsList.splice(0, 3)

  const metaSeo = data.allWpTag?.nodes[0]?.seo

  const generateLinkImage = (url) => {
    if (url) {
      return url.replace(`${process.env.GATSBY_SITE_SOURCE_IMAGE}`, `${process.env.GATSBY_SITE_CDN_IMAGE}`)
    } else {
      return `${process.env.GATSBY_SITE_URL}/default-image.png`
    }
  }

  const renderBreadcrumbs = (post) => {
    const breadcrums = post?.seo?.breadcrumbs;
    if (breadcrums?.length > 0) {
      return `{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "${breadcrums[0].text}",
          "item": "${process.env.GATSBY_SITE_URL}"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "${breadcrums[1].text}",
          "item": "${process.env.GATSBY_SITE_URL}${breadcrums[1].url}"
        }]
      }`
    } else {
      return null;
    }
  }

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo
          metaTitle={`Thông tin mới nhất về ${tagName} - Ttravel`}
          metaDescription={`Tổng hợp bài viết, tin tức mới nhất trong ngày về ${tagName} cập nhật liên tục - Tổng hợp tin tức, cẩm nang du lịch hàng đầu Việt Nam`}
          canonical={`${process.env.GATSBY_SITE_URL}${metaSeo?.canonical}`}
          image={metaSeo?.opengraphImage || `${process.env.GATSBY_SITE_URL}/default-image.png`}
        />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo
        metaTitle={`Thông tin mới nhất về ${tagName} - Ttravel`}
        metaDescription={`Tổng hợp bài viết, tin tức mới nhất trong ngày về ${tagName} cập nhật liên tục - Tổng hợp tin tức, cẩm nang du lịch hàng đầu Việt Nam`}
        canonical={`${process.env.GATSBY_SITE_URL}${metaSeo?.canonical}`}
        image={metaSeo?.opengraphImage || `${process.env.GATSBY_SITE_URL}/default-image.png`}
        breadcrumbs={renderBreadcrumbs(metaSeo?.breadcrumbs)}
      />

      <div className="category-page__highlight__section bg-color">
        <div className="container highlight__section">
          <div className="category-page__header">
            <div className="category-page__header__breadcrumb">
              <div className="category-page__header__breadcrumb__container">
                <Link className="category-page__header__breadcrumb__link" to="/">Trang chủ</Link>
                <i className="fa-solid fa-angle-right category-page__header__breadcrumb__separator"></i>
                <p className="category-page__header__breadcrumb__title">{tagName}</p>
              </div>
              <div className="category-page__header__breadcrumb__pagination">
                <p className="category-page__header__breadcrumb__pagination__content">
                  Trang
                  <span> {pageContext.humanPageNumber} </span>
                  của
                  <span> {pageContext.numberOfPages} </span>
                </p>
              </div>
            </div>
            <div className="category-page__header__title">
              <h1>{tagName}</h1>
            </div>
            {/* <div className="category-page__header__description">
              <p>Nhà là "Tổ ấm", là nơi trở về sau những mệt mỏi và bộn bề cuộc sống. Hiểu được điều đó, Cleanipedia là nơi cung cấp cho bạn thông tin, mẹo vặt chăm sóc, vệ sinh nhà cửa luôn sạch sẽ, thư giãn và ấm áp. Đặc biệt, khi dịch bệnh Covid lại “hoành hành”, hãy cùng Cleanipedia phòng chống, bảo vệ gia đình bằng cách áp dụng các cách làm sạch, khử khuẩn nhà cửa để cùng nhau vượt qua đại dịch này nhé.</p>
            </div> */}
          </div>
          <div className="category-page__highlight__post__container">
            <div className="category-page__highlight__post post__large">
              <div className="category-page__highlight__post__large__image">
                <Link to={posts[0].node?.uri} itemProp="url">
                  <img
                    src={generateLinkImage(posts[0].node?.seo?.opengraphImage?.mediaItemUrl || `${process.env.GATSBY_SITE_URL}/default-image.png`)}
                    alt={posts[0].node?.title || `${process.env.GATSBY_SITE_NAME}`}
                    placeholder="blurred"
                    loading="eager"
                  />
                </Link>
              </div>
              <div className="category-page__highlight__post__large">
                <Link to={posts[0].node?.uri} itemProp="url">
                  <p className="category-page__highlight__post__large__title" itemProp="headline">{parse(posts[0]?.node?.title || "")}</p>
                </Link>
                <Link to={`/${posts[0]?.node?.categories?.nodes[0]?.slug}/ `} itemProp="url" className="category-page__more__post__item__link">
                  <p className="style-tag">{parse(posts[0]?.node?.categories?.nodes[0]?.name || ``)}</p>
                </Link>
              </div>
            </div>
            <div className="category-page__highlight__post post__small__box">
              {
                [...posts].slice(1, 3).map((post, index) => (
                  <article
                    key={index}
                    className="category-page__highlight__post__small__item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <div className="category-page__highlight__post__small__item__image">
                      <Link to={post?.node?.uri} itemProp="url">
                        <img
                          src={generateLinkImage(post.node?.seo?.opengraphImage?.mediaItemUrl || `${process.env.GATSBY_SITE_URL}/default-image.png`)}
                          alt={post.node?.title || `${process.env.GATSBY_SITE_NAME}`}
                          placeholder="blurred"
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <div className="category-page__highlight__post__small__item__content">
                      <Link to={post?.node?.uri} itemProp="url">
                        <p className="category-page__highlight__post__small__item__content__title" itemProp="headline">{parse(post?.node?.title || "")}</p>
                      </Link>
                      <Link to={`/${post?.node?.categories?.nodes[0]?.slug}/ `} itemProp="url" className="category-page__more__post__item__link">
                        <p className="style-tag">{parse(post?.node?.categories?.nodes[0]?.name || ``)}</p>
                      </Link>
                    </div>
                  </article>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className="category-page__more__section">
        <div className="container more__section">
          <h2 className="category-page__more__section__title">
            <span>Đọc Tiếp</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19"><path d="M13.235 10.185c-5.434.855-5.955 1.548-6.598 8.772-.643-7.224-1.164-7.917-6.597-8.772 5.433-.855 5.954-1.548 6.597-8.771.643 7.223 1.164 7.916 6.598 8.771zm1.725-6.289c-2.386.376-2.615.68-2.898 3.853-.282-3.173-.511-3.478-2.898-3.853 2.387-.376 2.616-.68 2.898-3.853.283 3.173.512 3.477 2.898 3.853z" fill="#424242"></path></svg>
          </h2>
          <div className="category-page__more__post__container">
            {
              postsList?.length > 0 && postsList.map((post, index) => (
                <div key={index} className="category-page__more__post__item">
                  <div className="category-page__more__post__item__image">
                    <Link to={post?.node?.uri} itemProp="url">
                      <img
                        src={generateLinkImage(post.node?.seo?.opengraphImage?.mediaItemUrl || `${process.env.GATSBY_SITE_URL}/default-image.png`)}
                        alt={post.node?.title || `${process.env.GATSBY_SITE_NAME}`}
                        placeholder="blurred"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <div className="category-page__more__post__item__content">
                    <Link to={post?.node?.uri} itemProp="url">
                      <p className="category-page__more__post__item__content__title" itemProp="headline">{parse(post?.node?.title || "")}</p>
                    </Link>
                    <Link to={`/${post?.node?.categories?.nodes[0]?.slug}/ `} itemProp="url" className="category-page__more__post__item__link">
                      <p className="style-tag">{parse(post?.node?.categories?.nodes[0]?.name || ``)}</p>
                    </Link>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="category-page__pagination">
        <p className="category-page__pagination-percent-box">
          {totalPosts <= pageContext.limit && `${totalPosts} / ${totalPosts}`}
          {totalPosts > pageContext.limit && `${pageContext.limit * pageContext.humanPageNumber} / ${totalPosts}`}
          <span
            className="pagination__progress-bar"
            style={{
              backgroundImage: `linear-gradient(to right,#6ec6d5 ${Math.round(((pageContext.limit * pageContext.humanPageNumber) / totalPosts) * 100)}%,#fff ${Math.round(((pageContext.limit * pageContext.humanPageNumber) / totalPosts) * 100)}%)`
            }}
          ></span>
        </p>
        <div className="category-page__pagination-button-group">
          {pageContext.previousPagePath && <Link to={`/${pageContext.previousPagePath}`} className="pagination__group shadow rounded">Trước</Link>}
          {pageContext.nextPagePath && <Link to={`/${pageContext.nextPagePath}`} className="pagination__group shadow rounded">Kế tiếp</Link>}
        </div>
      </div>
    </Layout>
  )
}

export default ListPostByTag

export const pageQuery = graphql`
  query ListPostByTag(
    $slug: String!, 
    $skip: Int!, 
    $limit: Int!
  ) {
    allWpPost(
      skip: $skip
      limit: $limit
      filter: {tags: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          uri
          title
          seo {
            opengraphImage {
              mediaItemUrl
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    allWpTag( filter: { slug: { eq: $slug } } ) {
      nodes {
        name
        seo {
          canonical
            breadcrumbs {
              text
              url
            }
          title
          metaDesc
          opengraphImage {
            mediaItemUrl
          }
        }
      }
    }
  }
`